.suggestion-view { 
  position: relative;
  height: fit-content;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  z-index: 0;
}

@media (min-width: 768px) {
  .suggestion-view {
    position: fixed;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40rem;
  }
}
