@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./fonts.css');

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 100%;
}

body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  background-color: #F5F5F5;
  z-index: 0;
  overflow-x: hidden;
  width: 100%;
}

h1, h2 {
  font-family: 'Francois One', sans-serif;
}

h1 {
  font-size: 130px;
  line-height: 184px;
}

h2 {
  font-size: 30px;
  line-height: 43px;
}

@media (min-width: 768px) {
  h2 {
    font-size: 40px;
    line-height: 57px;
  }
}

h3 {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
}

@media (min-width: 768px) {
  h3 {
    font-family: 'Francois One', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 31px;
  }
}

.large-h3 {
  font-family: 'Francois One', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 31px;
}

p, a, button {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
}

label {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
}

a {
  color: #E10D00;
  text-decoration: underline;
}

input::placeholder, textarea::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #AAAAAA;
  opacity: 1; /* Firefox */
}

@media (min-width: 768px) {
  input::placeholder, textarea::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #CFCFCF;
  }
}

ol {
  list-style-type: decimal;
  padding-left: 16px;
}

.text-big {
  font-size: 24px;
  line-height: 34px;
}

.text-large {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
}

@media (min-width: 768px) {
  .text-large {
    font-family: 'Francois One', sans-serif;
    font-weight: normal;
    font-size: 30px;
    line-height: 43px;
  }
}

.text-huge {
  font-family: 'Francois One', sans-serif;
  font-size: 90px;
  line-height: 90px;
}

@media (min-width: 1024px) {
  .text-huge {
    font-family: 'Francois One', sans-serif;
    font-size: 128px;
    line-height: 146px;
  }
}

.text-light-red {
  color: #F3666F;
}

.text-light-blue {
  color: #5C92FD;
}

.text-light-purple {
  color: #A77AB7;
}

.text-small-gray {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #505050;
}

.validation-error {
  color: #E10D00;
  font-size: 16px;
  line-height: 22px;
  font-style: italic;
  margin-bottom: 8px;
}

.underlabel {
  color: #444444;
  font-size: 16px;
  line-height: 22px;
  /*margin-bottom: 0;*/
}

.blue-link {
  color: #3E65E4;
  text-decoration: none;
}

.border-top-red {
  border-top: 2px solid #E10D00;
}

.border-top-blue {
  border-top: 2px solid #3E65E4;
}

.border-top-cat-1 {
  border-top: 2px solid #A5F2E7;
}
.border-top-cat-2 {
  border-top: 2px solid #A8F1A4;
}
.border-top-cat-3 {
  border-top: 2px solid #F6F17C;
}
.border-top-cat-4 {
  border-top: 2px solid #FED167;
}
.border-top-cat-5 {
  border-top: 2px solid #FCBEA0;
}
.border-top-cat-6 {
  border-top: 2px solid #FBA9DD;
}
.border-top-cat-7 {
  border-top: 2px solid #ADC5FB;
}
.border-top-cat-7 {
  border-top: 2px solid #A9DCF1;
}

.border-bottom-blue {
  border-bottom: 2px solid #3E65E4;
}
.border-bottom-cat-1 {
  border-bottom: 2px solid #A5F2E7;
}
.border-bottom-cat-2 {
  border-bottom: 2px solid #A8F1A4;
}
.border-bottom-cat-3 {
  border-bottom: 2px solid #F6F17C;
}
.border-bottom-cat-4 {
  border-bottom: 2px solid #FED167;
}
.border-bottom-cat-5 {
  border-bottom: 2px solid #FCBEA0;
}
.border-bottom-cat-6 {
  border-bottom: 2px solid #FBA9DD;
}
.border-bottom-cat-7 {
  border-bottom: 2px solid #ADC5FB;
}
.border-bottom-cat-7 {
  border-bottom: 2px solid #A9DCF1;
}

.button-primary {
  align-items: center;
  width: fit-content;
  background-color: #E10D00;
  border: 2px solid #E10D00;
  border-radius: 100px;
  padding: 10px 19px;
  color: #FFFFFF;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
}

.button-secondary {
  align-items: center;
  width: fit-content;
  border: 2px solid #505050;
  background-color: transparent;
  border-radius: 100px;
  padding: 10px 19px;
  color: #505050;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
}

.button-blue {
  align-items: center;
  width: fit-content;
  border: 2px solid #3E65E4;
  background-color: #3E65E4;
  border-radius: 100px;
  padding: 10px 19px;
  color: #FFFFFF;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
}

.button-disabled {
  align-items: center;
  width: fit-content;
  border: 2px solid #505050;
  background-color: #505050;
  border-radius: 100px;
  padding: 10px 19px;
  color: #FFFFFF;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
}

.button-square {
  border-radius: 0;
  padding: 0 10px;
  font-weight: normal;
}

.button-inactive {
  border-radius: 0;
  padding: 0 10px;
  font-weight: bold;
  background-color: transparent;
  color: #3E65E4;
  border: none;
}

.overlay {
  position: fixed;
  left:0px;
  top:0px;
  width:100%;
  height: 100%;
  z-index: 1;
  background-color: #3E65E4;
  opacity: 0.8;
}

.w-fit-content {
  width: fit-content;
}

.min-h-32 {
  min-height: 8rem;
}

.disabled {
  background-color: transparent;
  color: #3E65E4;
  border: none;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .flex-column {
    flex-direction: column;
  }
}

@media (max-width: 640px) {
  .flex-column-sm {
    flex-direction: column;
  }

  .self-center-sm {
    align-self: center;
  }

  .py-5-sm {
    padding: 40px 0 20px 0;
  }
}