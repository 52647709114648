.prizecard {
  width: 150px;
  height: 150px;
  background: transparent;
  border-radius: 50%;
  transform: rotate(-15deg);
  border: 2px solid #5C92FD;
  color: #5C92FD;
  font-weight: bold;
}

.prizecard p {
  font-weight: bold;
}